body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.app {
  text-align: center;
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 0 auto;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

textarea {
  width: 95%;
  max-width: 95%;
  margin: 10px 0;
}

.drop-area {
  padding: 10px;
  background-color: #f0f0f0;
  min-height: 100px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 5px 0;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.item-input {
  flex-grow: 1;
  padding: 5px;
  margin-right: 10px;
}

.button-group {
  display: flex;
  gap: 5px;
}

.icon-button {
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #333;
}

.icon-button:hover {
  color: #007bff;
}

.icon-button.delete {
  color: red;
}

.add-item-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-item-button:hover {
  background-color: #0056b3;
}

.main-button-group {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-bottom: 1em;
}

@media (max-width: 600px) {
  .app {
    padding: 10px;
    max-width: 100%;
  }

  .item-container {
    flex-direction: column;
    align-items: stretch;
  }

  .item-input {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .button-group {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 5px;
  }

  .icon-button {
    font-size: 1rem;
  }

  .add-item-button {
    width: 100%;
  }
}

.drag-handle {
  padding: 0 10px;
  font-size: 1.2rem;
  color: #333;
  cursor: grab;
}

.drag-handle:hover {
  color: #007bff;
}